<div class="section-holder">
  <div class="section-title-holder" *ngIf="hasSectionTitle">
    <h1>{{ section?.translations | feedbackTranslate: 'section-title': selectedLanguage }}</h1>
  </div>
  <div class="section-description-holder" *ngIf="hasSectionText"
       [innerHTML]="section?.translations | feedbackTranslate: 'section-text': selectedLanguage"
  ></div>
  <div [ngClass]="hasSectionText ? 'section-content-holder section-has-description' : 'section-content-holder'">
    <mat-tab-group
      mat-stretch-tabs
      animationDuration="0ms"
      class="example-stretched-tabs mat-elevation-z4 dimension-tabs"
      [(selectedIndex)]="selectedTabIndex"
      (selectedTabChange)="onTabChanged()"
    >
      <mat-tab *ngFor="let item of section.subsections">
        <ng-template mat-tab-label>
          <img [src]='environment?.mediaUrl + item?.icon'/>
          <h5>{{ item?.translations | feedbackTranslate: 'subsection-name': selectedLanguage }}</h5>
        </ng-template>
        <div class="dimension-holder">
          <div class="dimension-text"
            [innerHTML]="item?.translations | feedbackTranslate: 'section-graph-summary': selectedLanguage"
          ></div>
          <div class="dimension-title">
            <h4 [ngStyle]="{'color': item.color}">{{ item?.translations | feedbackTranslate: 'subsection-name': selectedLanguage }}</h4>
          </div>
          <div class="dimension-items" *ngFor="let bar of item.bars; let i=index">
            <div class="dimension-item">
              <div class="dimension-item-text-holder">
                <div class="dimension-item-title-holder">
                  <a *ngIf="bar.has_details" (click)="goToDetail(bar.detail, item, bar, results[item.id] ? results[item.id][i] : null)">
                    <h5>{{ bar?.translations | feedbackTranslate: 'bar-title': selectedLanguage }}</h5>
                  </a>
                  <h5 *ngIf="!bar.has_details">{{ bar?.translations | feedbackTranslate: 'bar-title': selectedLanguage }}</h5>
                </div>
                <div class="dimension-item-description-holder small-description"
                  [innerHTML]="bar?.translations | feedbackTranslate: 'bar-text': selectedLanguage"
                ></div>
              </div>
              <div class="dimension-item-bar-holder" *ngIf="results[item.id] && results[item.id][i]">
                <feedback-bar
                  [minValue]="results[item.id][i].min_value"
                  [maxValue]="results[item.id][i].max_value"
                  [minRange]="results[item.id][i].min_range"
                  [maxRange]="results[item.id][i].max_range"
                  [barValue]="results[item.id][i].value"
                  [barColor]="item.color"
                ></feedback-bar>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
