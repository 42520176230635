import { Action, createReducer, on } from '@ngrx/store';

import * as resultsActions from '../results/results.actions';

export const resultsFeatureKey = 'results';

export interface ResultsState {
  loadingResults: boolean;
  resultsLoaded: boolean;
  results: any;
}

export const initialState: ResultsState = {
  loadingResults: false,
  resultsLoaded: false,
  results: null
};

export const reducer = createReducer(
  initialState,
  on(resultsActions.loadResults, state => {
    return {
      ...state,
      loadingResults: true,
      resultsLoaded: false
    };
  }),
  on(resultsActions.loadResultsSuccess, (state, { results }) => {
    return {
      ...state,
      loadingResults: false,
      resultsLoaded: true,
      results
    };
  }),
  on(resultsActions.loadResultsFailure, state => {
    return {
      ...state,
      loadingResults: false,
      resultsLoaded: false
    };
  })
);

export function resultsReducer(state: ResultsState | undefined, action: Action): ResultsState {
  return reducer(state, action);
}
