import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'feedback-bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.scss']
})
export class BarComponent implements OnInit {
  @Input() minRange: number;
  @Input() maxRange: number;
  @Input() minValue: number;
  @Input() maxValue: number;
  @Input() barValue: number;
  @Input() barColor: string;
  @Input() barSize = "default";

  leftValue: number;
  widthValue: number;
  pinPosition: number;
  barColorWithTransparency: string;

  constructor() { }

  ngOnInit(): void {
    this.leftValue = 100 * (this.minValue - this.minRange) / (this.maxRange - this.minRange);
    this.widthValue = 100 * (this.maxValue - this.minRange) / (this.maxRange - this.minRange) - this.leftValue;
    this.pinPosition = this.calculatePinPosition();
    this.barColorWithTransparency = this.barColor + '80';
  }

  calculatePinPosition(): number {
    if (this.barValue > this.maxRange) {
      return 100;
    } else if (this.barValue < this.minRange) {
      return 0;
    }

    return 100 * (this.barValue - this.minRange) / (this.maxRange - this.minRange);
  }
 }
