import { createAction, props } from '@ngrx/store';

export const loadResults = createAction(
  '[Results] Load Results',
  props<{ resultsHash: string }>()
);

export const loadResultsSuccess = createAction(
  '[Results] Load Results Success',
  props<{ results: any }>()
);

export const loadResultsFailure = createAction(
  '[Results] Load Results Failure',
  props<{ error: any }>()
);
