import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';

import { Section } from '../../../models/section';
import { selectSelectedLanguage } from '../../../state/feedback/feedback.selectors';
import { AppState } from '../../../state';
import { environment } from '../../../../environments/environment';
import { Detail } from '../../../models/detail';
import { Subsection } from '../../../models/subsection';
import { Bar } from '../../../models/bar';

@Component({
  selector: 'feedback-dimension-section',
  templateUrl: './dimension.component.html',
  styleUrls: ['./dimension.component.scss']
})
export class DimensionComponent implements OnInit {
  @Input() section: Section;
  @Input() results: any;

  environment = environment;
  selectedLanguage: string;

  hasSectionTitle: boolean;
  hasSectionText: boolean;

  activeTab: any;
  selectedTabIndex = 0;

  constructor(
    private store: Store<AppState>,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.store.select(selectSelectedLanguage).subscribe(selectedLanguage => {
      this.selectedLanguage = selectedLanguage;
    });

    this.hasSectionTitle = this.section.translations
      .find((translation) =>
        (translation.identifier === 'section-title') && translation.language === this.selectedLanguage)
      .translation !== '';

    this.hasSectionText = this.section.translations
      .find((translation) =>
        (translation.identifier === 'section-text') && translation.language === this.selectedLanguage)
      .translation !== '';

    if (localStorage.getItem('feedback-bar-tab-id')) {
      this.selectedTabIndex = parseInt(localStorage.getItem('feedback-bar-tab-id'));
    }

    this.prepareDimensionsResults();
  }

  ngAfterViewInit(): void {
    this.changeTabColor();
  }

  changeTabColor() {
    let tabs = document.getElementsByClassName('mat-tab-label') as HTMLCollectionOf<HTMLElement>;

    for (let i = 0; i < tabs.length; i++) {
      if (!tabs[i].classList.contains('mat-tab-label-active')) {
        tabs[i].style.backgroundColor = '#dcdcdc';
      }
    }

    this.activeTab = document.getElementsByClassName('mat-tab-label-active')[0];
    this.activeTab.style.backgroundColor = this.section.subsections[this.selectedTabIndex].color;
  }

  onTabChanged() {
    this.changeTabColor();
  }

  prepareDimensionsResults(): any {
    if (!this.results) {
      this.results = {};

      this.section.subsections.forEach((subsection) => {
        this.results[subsection.id] = [];
        const result = {
          max_value: 2*7/3+0.5,
          min_value: 7/3-0.5,
          min_range: 0,
          max_range: 7,
          section_id: subsection.template_section_id,
          subsection: subsection.id,
          value: 3.5
        };
        subsection.bars.forEach(() => {
          this.results[subsection.id].push(result);
        })
      });
    }
  }

  goToDetail(detail: Detail, item: Subsection, bar: Bar, result: any) {
    const detailsToSend = {
      details: detail,
      url: this.router.url,
      sectionId: this.section.id,
      barSubTitle: bar.translations.filter(translation =>
        translation.language === this.selectedLanguage && translation.identifier === 'bar-title')[0].translation,
      barTitle: item.translations.filter(translation =>
        translation.language === this.selectedLanguage && translation.identifier === 'subsection-name')[0].translation,
      barResult: result,
      barColor: item.color,
      barTabId: this.selectedTabIndex
    };

    localStorage.setItem('feedback-item-details', JSON.stringify(detailsToSend));
    this.router.navigate(['details']);
  }
}
