import { Pipe, PipeTransform } from '@angular/core';

import { Translation } from '../../models/translation';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'feedbackTranslate'
})
export class FeedbackTranslatePipe implements PipeTransform {

  transform(translations: Translation[], identifier: string, language: string): string {
    if (!translations) {
      return null;
    }

    let result = '';

    if (!language) {
      language = environment.defaultLang;
    }

    translations.forEach(translation => {
      if (translation.language === language && translation.identifier === identifier) {
        result = translation.translation;
        return;
      }
    });

    return result;
  }

}
