import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';

import { Section } from '../../../models/section';
import { environment } from '../../../../environments/environment';
import { AppState } from '../../../state';
import { selectSelectedLanguage } from '../../../state/feedback/feedback.selectors';
import { Detail } from '../../../models/detail';

@Component({
  selector: 'feedback-generic-bar-graph-section',
  templateUrl: './generic-bar-graph.component.html',
  styleUrls: ['./generic-bar-graph.component.scss']
})
export class GenericBarGraphComponent implements OnInit {
  @Input() section: Section;

  environment = environment;
  selectedLanguage: string;

  hasSectionTitle: boolean;
  hasSectionText: boolean;

  constructor(
    private store: Store<AppState>,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.store.select(selectSelectedLanguage).subscribe(selectedLanguage => {
      this.selectedLanguage = selectedLanguage;
    });

    this.hasSectionTitle = this.section.translations
      .find((translation) =>
        (translation.identifier === 'section-title') && translation.language === this.selectedLanguage)
      .translation !== '';

    this.hasSectionText = this.section.translations
      .find((translation) =>
        (translation.identifier === 'section-text') && translation.language === this.selectedLanguage)
      .translation !== '';
  }

  goToDetail(detail: Detail) {
    const detailsToSend = {
      details: detail,
      url: this.router.url,
      sectionId: this.section.id
    };

    localStorage.setItem('feedback-item-details', JSON.stringify(detailsToSend));
    this.router.navigate(['details']);
  }
}
