<div class="loading-spinner-holder"
     *ngIf="(loadingFeedback$ | async) || (!templateWithoutResults && (loadingResults$ | async))">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="(feedbackLoaded$ | async) && (templateWithoutResults || (resultsLoaded$ | async))">
  <mat-toolbar color="primary" *ngIf="innerWidth > 768">
    <ng-container *ngTemplateOutlet="layoutLg"></ng-container>
  </mat-toolbar>
  <div class="mobile-container" *ngIf="innerWidth <= 768">
    <ng-container *ngTemplateOutlet="layoutSmMd"></ng-container>
  </div>
</div>

<ng-template #layoutLg>
  <div class="logo-holder">
    <img [src]='environment?.mediaUrl + feedback?.image_url'/>
  </div>
  <span class="horizontal-spacer"></span>
  <div class="title-holder">
    <h2>{{ feedback?.translations | feedbackTranslate: 'title-main': selectedLanguage }}</h2>
  </div>
  <span class="horizontal-spacer"></span>
  <div class="buttons-holder">
    <div class="languages-holder" *ngIf="feedbackLanguages.length > 1">
      <mat-form-field appearance="none">
        <mat-select
          matNativeControl
          (selectionChange)="selectLanguage($event)"
          [(value)]="selectedLanguage"
          panelClass="language-selector">
          <mat-option *ngFor="let option of feedbackLanguages" [value]="option">{{ option }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="download-holder">
      <button *ngIf="!showSpinner" mat-button [disabled]="!hasParticipantHash" (click)="downloadPDF()">{{ 'download' | translate }}</button>
      <button *ngIf="showSpinner" mat-button>{{ 'downloading' | translate }}
        <mat-icon>
          <mat-spinner diameter="20">
          </mat-spinner>
        </mat-icon>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #layoutSmMd>
  <mat-toolbar color="primary" class="mobile-toolbar">
    <div class="logo-holder">
      <img [src]='environment?.mediaUrl + feedback?.image_url'/>
    </div>
    <span class="horizontal-spacer"></span>
    <div class="sidenav-opener-holder">
      <button mat-icon-button (click)="nav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  </mat-toolbar>

  <mat-sidenav-container class="sidenav-container" [style.marginTop.px]="56">
    <mat-sidenav #nav mode="over" fixedInViewport="true" fixedTopGap="56" position="end">
      <div class="languages-holder" *ngIf="feedbackLanguages.length > 1">
        <div class="language" *ngFor="let language of feedbackLanguages">
          <button mat-button (click)="selectLanguageMobile($event)">{{language}}</button>
        </div>
      </div>
      <div class="download-holder">
        <button mat-button [disabled]="true" [disabled]="!hasParticipantHash" (click)="downloadPDF()">Download</button>
      </div>
      <div class="title-holder">
        <span>© 2020, {{ feedback?.translations | feedbackTranslate: 'title-main': selectedLanguage }}</span>
      </div>
    </mat-sidenav>
  </mat-sidenav-container>
</ng-template>
