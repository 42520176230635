import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

export interface ApiRequestOptions {
  headers?: HttpHeaders;
  observe?: any;
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
  body?: any;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private API_URL = environment.apiUrl;

  public constructor(public http: HttpClient) {
  }

  public get<T>(endPoint: string, options?: ApiRequestOptions): Observable<T> {
    return this.http.get<T>(`${this.API_URL}${endPoint}`, options);
  }
}
