import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';

import { Section } from '../../../models/section';
import { environment } from '../../../../environments/environment';
import { AppState } from '../../../state';
import { selectSelectedLanguage } from '../../../state/feedback/feedback.selectors';
import { Subsection } from '../../../models/subsection';

@Component({
  selector: 'feedback-profile-section',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @Input() section: Section;
  @Input() results: any;

  environment = environment;
  selectedLanguage: string;

  hasSectionTitle: boolean;
  hasSectionText: boolean;

  profileChartData: any;
  profileChartLabels: any;
  profileChartLegend: boolean;
  profileChartType: string;
  profileChartOptions: any;

  activeItemId: number;

  constructor(
    private store: Store<AppState>,
    private router: Router
  ) {
    this.initProfileGraphValues();
  }

  ngOnInit(): void {
    this.store.select(selectSelectedLanguage).subscribe(selectedLanguage => {
      this.selectedLanguage = selectedLanguage;
    });

    this.hasSectionTitle = this.section.translations
      .find((translation) =>
        (translation.identifier === 'section-title') && translation.language === this.selectedLanguage)
      .translation !== '';

    this.hasSectionText = this.section.translations
      .find((translation) =>
        (translation.identifier === 'section-text') && translation.language === this.selectedLanguage)
      .translation !== '';

    this.setActiveIndex();

    this.showProfileResults();
  };

  initProfileGraphValues() {
    this.profileChartData = [{
      data: [],
      label: '',
      pointStyle:[],
      fill: false,
      backgroundColor: '#304757',
      showLine: false,
      borderWidth: 0.0001,
      pointHitRadius: 20
    }];

    this.profileChartLabels = [];
    this.profileChartLegend = true;
    this.profileChartType = 'radar';

    this.profileChartOptions = {
      animation: {
        onProgress: function(animation) {
          let context = animation.chart.ctx.canvas.getContext('2d');
          const pixelRatio = animation.chart.currentDevicePixelRatio;

          context.globalCompositeOperation = 'destination-over';

          context.font = "500 24px 'Open Sans', sans-serif";
          context.textAlign = 'center';
          context.textBaseline = 'middle';
          context.lineWidth = 0;
          context.strokeStyle = '#fff';
          context.fillStyle = '#fff';

          const x = animation.chart.ctx.canvas.width/2/pixelRatio;
          const y = animation.chart.ctx.canvas.height/2/pixelRatio;

          context.fillText('YOU', x, y);
          context.strokeText('YOU', x, y);

          // draw circles
          const colors = ['#61799E', '#7187A8', '#8799B5', '#A4B2C7', '#CBD3DF', '#E4E9EF'];

          context.beginPath();
          for (let i = 0; i < 6; i++) {
            context.arc(x, y, 2*y/7 + (y/7) * i, 0, 2 * Math.PI);
            context.fillStyle = colors[i];
            context.fill();
          }
          context.closePath();

          context.fillStyle = '#fff';
          context.globalCompositeOperation = 'source-over';
        }
      },
      layout: {
        padding: 30
      },
      legend: {
        display: false
      },
      responsive: true,
      responsiveAnimationDuration: 1,
      maintainAspectRatio: false,
      backgroundColor: '#304757',
      angleLines: {
        display: false
      },
      scale: {
        ticks: {
          stepSize: 1,
          suggestedMin: 0,
          display: false,
          maxTicksLimit: 10,
          max: 100
        },
        angleLines: {
          display: false
        },
        gridLines: {
          circular: false,
          display: false
        },
        pointLabels: {
          display: false
        }
      },
      tooltips: {
        enabled: false,

        callbacks: {
          label: function(tooltipItem, data) {
            return data.labels[tooltipItem.index];
          }
        },

        custom: this.customToolTip
      }
    };
  }

  customToolTip = function(tooltipModel) {
    let tooltipEl = document.getElementById('chartjs-tooltip');

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip';
      tooltipEl.innerHTML = '<div></div>';
      document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = '0';
      return;
    }

    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add('no-transform');
    }

    function getBody(bodyItem) {
      return bodyItem.lines;
    }

    // Set Text
    if (tooltipModel.body) {
      let bodyLines = tooltipModel.body.map(getBody);

      let innerHtml = '<div>';

      bodyLines.forEach(function(body) {
        let outerDivStyle = 'background: #304757';
        outerDivStyle += '; padding: 3px 9px';
        outerDivStyle += '; transform: skew(-20deg)';
        outerDivStyle += '; color: #fff';
        outerDivStyle += '; text-transform: uppercase';
        outerDivStyle += '; font-weight: 600';
        let innerDivStyle = 'transform: skew(20deg)';
        let innerDiv = '<div style="' + innerDivStyle + '">' + body + '</div>';
        let outerDiv = '<div style="' + outerDivStyle + '">' + innerDiv + '</div>';
        innerHtml += outerDiv;
      });

      let divRoot = tooltipEl.querySelector('div');
      divRoot.innerHTML = innerHtml;
    }

    // `this` will be the overall tooltip
    let position = this._chart.canvas.getBoundingClientRect();

    // Display, position, and set styles for font
    tooltipEl.style.opacity = '1';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 10 + 'px';
    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - 10 + 'px';
    tooltipEl.style.fontFamily = "'Open Sans', sans-serif";

    if (window.innerWidth > 1024) {
      tooltipEl.style.fontSize = '24px';
    } else {
      tooltipEl.style.fontSize = '16px';
    }

    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
    tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
    tooltipEl.style.pointerEvents = 'none';
  };

  showProfileResults() {
    this.profileChartLabels = [];
    this.profileChartData[0].data = [];

    if (this.results) {
      this.section.subsections.forEach((subsection) => {
        this.profileChartData[0].data.push(this.results[subsection.id][0].value);
      });
    } else {
      this.section.subsections.forEach(() => {
        this.profileChartData[0].data.push(50);
      });
    }

    this.section.subsections.forEach((subsection) => {
      if(subsection.icon) {
        let profileImage = document.createElement('img');
        profileImage.setAttribute('src', this.environment.mediaUrl + subsection.icon);
        profileImage.setAttribute('alt', 'a');

        if (window.innerWidth > 1024) {
          profileImage.setAttribute('height', '70');
          profileImage.setAttribute('width', '70');
        } else {
          profileImage.setAttribute('height', '45');
          profileImage.setAttribute('width', '45');
        }

        this.profileChartData[0].pointStyle.push(profileImage);
      }

      this.profileChartLabels.push(
        subsection.translations.filter(profile =>
          profile.language === this.selectedLanguage && profile.identifier === 'subsection-name')[0].translation
      );
    });
  }

  goToDetail(item: Subsection): void {
    const detailsToSend = {
      details: item.detail,
      url: this.router.url,
      sectionId: this.section.id,
      subsectionId: item.id
    };

    localStorage.setItem('feedback-item-details', JSON.stringify(detailsToSend));
    this.router.navigate(['details']);
  }

  profileChartClicked(event: any): void {
    if(event.active && event.active[0] !== undefined) {
      this.activeItemId = this.section.subsections[event.active[0]._index].id;
    }
  }

  setActiveIndex(): void {
    if (localStorage.getItem('feedback-subsection-id')) {
      this.activeItemId = parseInt(localStorage.getItem('feedback-subsection-id'));
      return;
    }

    let minValue = 100;
    let activeIndex = this.section.subsections[0].id;

    for (let key in this.results) {
      if (this.results[key][0].value < minValue) {
        minValue = this.results[key][0].value;
        activeIndex = this.results[key][0].subsection_id;
      }
    }

    this.activeItemId = activeIndex;
  }
}
