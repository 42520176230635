import { createAction, props } from '@ngrx/store';

import { Feedback } from '../../models/feedback';

export const loadFeedback = createAction(
  '[Feedback] Load Feedback',
  props<{ templateHash: string }>()
);

export const loadFeedbackSuccess = createAction(
  '[Feedback] Load Feedback Success',
  props<{ feedback: Feedback }>()
);

export const loadFeedbackFailure = createAction(
  '[Feedback] Load Feedback Failure',
  props<{ error: any }>()
);

export const setSelectedLanguage = createAction(
  '[Feedback] Set Selected Language',
  props<{ selectedLanguage: string }>()
);

export const downloadCCPDF = createAction(
  '[Feedback] Download CC PDF',
  props<{ templateHash: string, participantHash: string, selectedLanguage: string }>()
);

export const dowloadCCPDFSuccess = createAction(
  '[Feedback] Download CC PDF Success'
);

export const dowloadCCPDFFailure = createAction(
  '[Feedback] Download CC PDF Failure',
  props<{ error: any }>()
);
