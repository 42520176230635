import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';

import { ChartsModule } from 'ng2-charts';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { DescriptionComponent } from './components/sections/description/description.component';
import { GenericBarGraphComponent } from './components/sections/generic-bar-graph/generic-bar-graph.component';
import { SectionsComponent } from './components/sections/sections.component';
import { CustomSerializer } from './core/custom-route-serializer';
import { FeedbackTranslatePipe } from './core/pipes/feedback-translate.pipe';
import { HomeComponent } from './components/home/home.component';
import { effects, reducers } from './state';
import { FooterComponent } from './components/footer/footer.component';
import { DetailsPageComponent } from './components/details-page/details-page.component';
import { ProfileComponent } from './components/sections/profile/profile.component';
import { LastSectionComponent } from './components/sections/last-section/last-section.component';
import { DimensionComponent } from './components/sections/dimension/dimension.component';
import { BarComponent } from './components/sections/dimension/bar/bar.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    DescriptionComponent,
    GenericBarGraphComponent,
    SectionsComponent,
    FeedbackTranslatePipe,
    HomeComponent,
    FooterComponent,
    DetailsPageComponent,
    ProfileComponent,
    LastSectionComponent,
    DimensionComponent,
    BarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatGridListModule,
    MatToolbarModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    StoreModule.forRoot(reducers, {}),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot({ serializer: CustomSerializer }),
    MatProgressSpinnerModule,
    ChartsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatTabsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
