import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SectionsComponent } from './components/sections/sections.component';
import { HomeComponent } from './components/home/home.component';
import { DetailsPageComponent } from './components/details-page/details-page.component';

const routes: Routes = [
  { path: 'details', component: DetailsPageComponent },
  { path: ':templateHash/user/:resultsHash', component: SectionsComponent },
  { path: ':templateHash', component: SectionsComponent },
  { path: '', component: HomeComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
