import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { selectFeedbackSections, selectIsFeedbackLoaded } from '../../state/feedback/feedback.selectors';
import { AppState } from '../../state';
import { Section } from '../../models/section';
import { SectionTypes } from '../../core/section-types';
import { selectIsResultsLoaded, selectResults } from '../../state/results/results.selectors';

@Component({
  selector: 'feedback-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.scss']
})
export class SectionsComponent implements OnInit {
  feedbackLoaded$: Observable<boolean>;
  resultsLoaded$: Observable<boolean>;
  templateWithoutResults = true;

  sections: Section[];
  sectionTypes = new SectionTypes();

  results: any;

  constructor(
    private store: Store<AppState>,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.feedbackLoaded$ = this.store.select(selectIsFeedbackLoaded);

    this.resultsLoaded$ = this.store.select(selectIsResultsLoaded);

    this.store.select(selectFeedbackSections).subscribe(feedbackSections => {
      this.sections = feedbackSections;
    });

    this.store.select(selectResults).subscribe(results => {
      this.results = results;
    });

    if (this.router.url.includes('user')) {
      this.templateWithoutResults = false;
    }
  }
}
