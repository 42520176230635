import {Component, HostListener, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import {MatSelectChange} from '@angular/material/select';

import {TranslateService} from '@ngx-translate/core';

import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';

import {AppState} from '../../state';
import {
  selectFeedback,
  selectFeedbackLanguages,
  selectFeedbackTranslations,
  selectIsFeedbackLoaded,
  selectIsLoadingFeedback,
  selectSelectedLanguage
} from '../../state/feedback/feedback.selectors';
import {setSelectedLanguage, downloadCCPDF} from '../../state/feedback/feedback.actions';
import {Feedback} from '../../models/feedback';
import {Translation} from '../../models/translation';
import {environment} from '../../../environments/environment';
import {selectIsLoadingResults, selectIsResultsLoaded} from '../../state/results/results.selectors';
import {CustomRouterNavigatedAction} from '../../core/custom-route-serializer';
import {Actions, ofType} from '@ngrx/effects';
import * as feedbackActions from "../../state/feedback/feedback.actions";

@Component({
  selector: 'feedback-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TopBarComponent implements OnInit {
  selectedLanguage = 'en';
  hasParticipantHash: boolean;
  
  innerWidth: number;

  environment = environment;
  loadingFeedback$: Observable<boolean>;
  feedbackLoaded$: Observable<boolean>;
  loadingResults$: Observable<boolean>;
  resultsLoaded$: Observable<boolean>;
  templateWithoutResults = true;

  feedback: Feedback;
  feedbackTranslations: Translation[];
  feedbackLanguages: string[];
  showSpinner = false;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private translate: TranslateService,
    private actions$: Actions
  ) {
  }

  ngOnInit(): void {

    this.innerWidth = window.innerWidth;
    
    this.hasParticipantHash = this.router.url.split('/', 4)[3] !== undefined;

    this.loadingFeedback$ = this.store.select(selectIsLoadingFeedback);
    this.feedbackLoaded$ = this.store.select(selectIsFeedbackLoaded);

    this.loadingResults$ = this.store.select(selectIsLoadingResults);
    this.resultsLoaded$ = this.store.select(selectIsResultsLoaded);

    this.translate.addLangs(['en', 'de', 'nl']);
    this.translate.setDefaultLang('en');

    this.store.select(selectSelectedLanguage).subscribe(selectedLanguage => {
      this.selectedLanguage = selectedLanguage;
    });

    this.store.select(selectFeedback).subscribe(feedback => {
      this.feedback = feedback;
    });

    this.store.select(selectFeedbackTranslations).subscribe(feedbackTranslations => {
      this.feedbackTranslations = feedbackTranslations;
    });

    this.store.select(selectFeedbackLanguages).subscribe(feedbackLanguages => {
      this.feedbackLanguages = feedbackLanguages;
    });

    if (this.router.url.includes('user')) {
      this.templateWithoutResults = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.innerWidth = window.innerWidth;
  }

  selectLanguage(event: MatSelectChange): void {
    this.changeLanguage(event.value);
  }

  selectLanguageMobile(event: Event): void {
    this.changeLanguage((event.target as HTMLInputElement).innerText)
  }

  changeLanguage(lang: string) {
    this.selectedLanguage = lang;
    localStorage.setItem('feedback-selected-language', this.selectedLanguage);
    this.translate.use(this.selectedLanguage);
    this.store.dispatch(setSelectedLanguage({selectedLanguage: this.selectedLanguage}));
  }

  downloadPDF(): void {
    const urlChucks = this.router.url.split('/', 4);
    this.showSpinner = true;

    this.store.dispatch(
      downloadCCPDF({
        templateHash: urlChucks[1],
        participantHash: urlChucks[3],
        selectedLanguage: this.selectedLanguage,
      })
    );

    this.actions$.pipe(ofType(feedbackActions.dowloadCCPDFSuccess)).subscribe((data: any) => {
      this.showSpinner = false;
    });

  }
}
