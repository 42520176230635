import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


import { Store } from '@ngrx/store';

import { environment } from '../../../environments/environment';
import { Detail } from '../../models/detail';
import { selectSelectedLanguage } from '../../state/feedback/feedback.selectors';
import { AppState } from '../../state';

@Component({
  selector: 'feedback-details-page',
  templateUrl: './details-page.component.html',
  styleUrls: ['./details-page.component.scss']
})
export class DetailsPageComponent implements OnInit {
  receivedDetails: any;
  detail: Detail;
  backUrl: string;
  sectionId: number;
  barTitle: string;
  barSubtitle: string;
  barResult: any;
  barColor: string;
  barTabId: number;
  subsectionId: number;

  environment = environment;
  selectedLanguage: string;

  constructor(
    private store: Store<AppState>,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.receivedDetails = JSON.parse(localStorage.getItem('feedback-item-details'));

    if (this.receivedDetails === null) {
      this.router.navigate(['']);
    } else {
      this.detail = this.receivedDetails.details;
      this.backUrl = this.receivedDetails.url;
      this.sectionId = this.receivedDetails.sectionId;
      this.barTitle = this.receivedDetails.barTitle;
      this.barSubtitle = this.receivedDetails.barSubTitle;
      this.barResult = this.receivedDetails.barResult;
      this.barColor = this.receivedDetails.barColor;
      this.barTabId = this.receivedDetails.barTabId;
      this.subsectionId = this.receivedDetails.subsectionId;
    }

    this.store.select(selectSelectedLanguage).subscribe(selectedLanguage => {
      this.selectedLanguage = selectedLanguage;
    });
  }

  goBack(): void {
    localStorage.clear();
    localStorage.setItem('feedback-section-id', JSON.stringify(this.sectionId));
    localStorage.setItem('feedback-selected-language', this.selectedLanguage);

    if (this.barTabId) {
      localStorage.setItem('feedback-bar-tab-id', JSON.stringify(this.barTabId));
    }

    if (this.subsectionId) {
      localStorage.setItem('feedback-subsection-id', JSON.stringify(this.subsectionId));
    }

    this.router.navigate([this.backUrl]);
  }
}
