<div class="sections-holder" *ngIf="(feedbackLoaded$ | async) && (templateWithoutResults || (resultsLoaded$ | async))">
  <div [id]="'section-' + section.id" *ngFor="let section of sections; let i=index">
    <feedback-description-section
      *ngIf="section.type===sectionTypes.DESCRIPTION"
      [section]="section"
    ></feedback-description-section>
    <feedback-generic-bar-graph-section
      *ngIf="section.type===sectionTypes.GENERIC_GRAPH_BAR"
      [section]="section"
    ></feedback-generic-bar-graph-section>
    <feedback-profile-section
      *ngIf="section.type===sectionTypes.PROFILE"
      [section]="section"
      [results]="results ? results.profile_results[section.id] : null"
    ></feedback-profile-section>
    <feedback-dimension-section
      *ngIf="section.type===sectionTypes.DIMENSION"
      [section]="section"
      [results]="results ? results.factor_results[section.id] : null"
    ></feedback-dimension-section>
    <feedback-last-section
      *ngIf="(sections.length-1)===i"
    ></feedback-last-section>
  </div>
</div>
