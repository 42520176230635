import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';

import { feedbackReducer, FeedbackState } from './feedback/feedback.reducer';
import { FeedbackEffects } from './feedback/feedback.effects';
import { resultsReducer, ResultsState } from './results/results.reducer';
import { ResultsEffects } from './results/results.effects';

export const appFeatureKey = 'app';

export interface AppState {
  router: RouterReducerState<any>;
  feedback: FeedbackState;
  results: ResultsState
}

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  feedback: feedbackReducer,
  results: resultsReducer
};

export const effects = [
  FeedbackEffects,
  ResultsEffects
];

export const metaReducers: MetaReducer<AppState>[] = [];
