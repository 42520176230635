import { createFeatureSelector, createSelector } from '@ngrx/store';

import { resultsFeatureKey, ResultsState } from './results.reducer';

export const selectResultsState = createFeatureSelector<ResultsState>(resultsFeatureKey);

export const selectIsLoadingResults = createSelector(
  selectResultsState,
  resultsState => resultsState.loadingResults
);

export const selectIsResultsLoaded = createSelector(
  selectResultsState,
  resultsState => resultsState.resultsLoaded
);

export const selectResults = createSelector(
  selectResultsState,
  resultsState => resultsState.results
);
