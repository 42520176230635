<div class="details-page-holder">
  <div class="details-page-top-bar">
    <div class="details-page-top-bar-shape"></div>
    <div class="detail-title-holder">
      <h2 *ngIf="!barTitle">
        {{ detail?.translations | feedbackTranslate: 'detail-title': selectedLanguage }}
      </h2>
      <h2 *ngIf="barTitle">
        {{ barTitle }}
      </h2>
    </div>
    <div class="back-button-holder">
      <a mat-raised-button
         (click)="goBack()">
        {{ 'back' | translate }}
      </a>
    </div>
  </div>
  <div class="details-page-content">
    <div class="detail-subtitle-holder" *ngIf="barSubtitle">
      <h4>{{ barSubtitle }}</h4>
    </div>
    <div class="detail-image-holder" *ngIf="detail?.media_url">
      <img [src]='environment?.mediaUrl + detail?.media_url'/>
    </div>
    <div class="detail-bar-holder" *ngIf="barResult">
      <feedback-bar
        [minValue]="barResult.min_value"
        [maxValue]="barResult.max_value"
        [minRange]="barResult.min_range"
        [maxRange]="barResult.max_range"
        [barValue]="barResult.value"
        [barColor]="barColor"
        barSize="large"
      ></feedback-bar>
    </div>
    <div class="detail-description-holder"
       [innerHTML]="detail?.translations | feedbackTranslate: 'detail-text': selectedLanguage"
    ></div>
  </div>
</div>
