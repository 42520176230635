import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as feedbackActions from '../feedback/feedback.actions';
import { CustomRouterNavigatedAction } from '../../core/custom-route-serializer';
import { FeedbackService } from '../../services/feedback/feedback.service';

@Injectable()
export class FeedbackEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private feedbackService: FeedbackService
  ) {}

  feedbackRoute$ = createEffect(() => this.actions$.pipe(
    ofType<CustomRouterNavigatedAction>(ROUTER_NAVIGATED),
    filter((route: CustomRouterNavigatedAction) => route.payload.routerState.params.hasOwnProperty('templateHash')),
    map(route => feedbackActions.loadFeedback({templateHash: route.payload.routerState.params.templateHash}))
  ));

  loadFeedback$ = createEffect(() => this.actions$.pipe(
    ofType(feedbackActions.loadFeedback),
    switchMap(({templateHash}) => {
      return this.feedbackService.getFeedbackByHash(templateHash).pipe(
        map((feedback) => feedbackActions.loadFeedbackSuccess({feedback})),
        catchError(((error: HttpErrorResponse) => of(feedbackActions.loadFeedbackFailure({error}))))
      );
    })
  ));

  loadFeedbackFailure$ = createEffect(() => this.actions$.pipe(
    ofType(feedbackActions.loadFeedbackFailure),
    tap(() => this.router.navigate([''])),
  ), {dispatch: false});

  downloadPDF$ = createEffect(() => 
    this.actions$.pipe(
      ofType(feedbackActions.downloadCCPDF),
      switchMap(({templateHash, participantHash, selectedLanguage}) => {
        return this.feedbackService.downloadPDF(templateHash, participantHash, selectedLanguage).pipe(
          map((result) => {
            const anchor = document.createElement('a');
            anchor.download = `CC-${templateHash}-${participantHash}-${selectedLanguage}.pdf`;
            anchor.href = window.URL.createObjectURL(new Blob([result], { type: 'application/pdf' }));
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            return feedbackActions.dowloadCCPDFSuccess();
          }),
          catchError(((error: HttpErrorResponse) => of(feedbackActions.dowloadCCPDFFailure({error}))))
        );
      })
    )
  );
}
