import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';

import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as resultsActions from '../results/results.actions';
import { ResultsService } from '../../services/results/results.service';
import { CustomRouterNavigatedAction } from '../../core/custom-route-serializer';

@Injectable()
export class ResultsEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private resultsService: ResultsService
  ) {}

  resultsRoute$ = createEffect(() => this.actions$.pipe(
    ofType<CustomRouterNavigatedAction>(ROUTER_NAVIGATED),
    filter((route: CustomRouterNavigatedAction) => route.payload.routerState.params.hasOwnProperty('resultsHash')),
    map(route => resultsActions.loadResults({ resultsHash: route.payload.routerState.params.resultsHash }))
  ));

  loadResults$ = createEffect(() => this.actions$.pipe(
    ofType(resultsActions.loadResults),
    switchMap(({ resultsHash }) => {
      return this.resultsService.getResults(resultsHash).pipe(
        map((results) => resultsActions.loadResultsSuccess({ results })),
        catchError(((error: HttpErrorResponse) => of(resultsActions.loadResultsFailure({ error }))))
      );
    })
  ));

  loadResultsFailure$ = createEffect(() => this.actions$.pipe(
    ofType(resultsActions.loadResultsFailure),
    tap(() => this.router.navigate([''])),
  ), { dispatch: false });
}
