<div class="section-holder">
  <div class="section-title-holder" *ngIf="hasSectionTitle">
    <h1>{{ section?.translations | feedbackTranslate: 'section-title': selectedLanguage }}</h1>
  </div>
  <div class="section-description-holder" *ngIf="hasSectionText"
   [innerHTML]="section?.translations | feedbackTranslate: 'section-text': selectedLanguage"
  ></div>
  <div [ngClass]="hasSectionText ? 'section-content-holder section-has-description' : 'section-content-holder'">
    <div class="items-holder">
      <div class="item-holder" *ngFor="let item of section.subsections">
        <div class="item-image-holder">
          <a *ngIf="item.has_details" (click)="goToDetail(item.detail)">
            <img [src]='environment?.mediaUrl + item?.icon'/>
          </a>
          <img *ngIf="!item.has_details" [src]='environment?.mediaUrl + item?.icon'/>
        </div>
        <div class="item-info-holder">
          <div class="item-title-holder">
            <a *ngIf="item.has_details" (click)="goToDetail(item.detail)">
              <h6>{{ item?.translations | feedbackTranslate: 'subsection-name': selectedLanguage }}</h6>
            </a>
            <h6 *ngIf="!item.has_details">
              {{ item?.translations | feedbackTranslate: 'subsection-name': selectedLanguage }}
            </h6>
          </div>
          <div class="item-description-holder small-description"
            [innerHTML]="item?.translations | feedbackTranslate: 'section-graph-summary': selectedLanguage"
          ></div>
        </div>
      </div>
    </div>
    <div class="image-holder">
      <img [src]='environment?.mediaUrl + section?.image_url'/>
    </div>
  </div>
</div>
