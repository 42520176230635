import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiService } from '../../core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ResultsService {
  constructor(private apiService: ApiService) {}

  getResults(resultsHash: string): Observable<any> {
    return this.apiService.get<any>(`/feedbackresults/${resultsHash}`);
  }
}
