import { createFeatureSelector, createSelector } from '@ngrx/store';

import { feedbackFeatureKey, FeedbackState } from './feedback.reducer';

export const selectFeedbackState = createFeatureSelector<FeedbackState>(feedbackFeatureKey);

export const selectIsLoadingFeedback = createSelector(
  selectFeedbackState,
  feedbackState => feedbackState.loadingFeedback
);

export const selectIsFeedbackLoaded = createSelector(
  selectFeedbackState,
  feedbackState => feedbackState.feedbackLoaded
);

export const selectFeedback = createSelector(
  selectFeedbackState,
  feedbackState => feedbackState.feedback
);

export const selectFeedbackLanguages = createSelector(
  selectFeedbackState,
  feedbackState => feedbackState.feedback?.languages?.split(',')
);

export const selectFeedbackTranslations = createSelector(
  selectFeedbackState,
  feedbackState => feedbackState.feedback?.translations
);

export const selectSelectedLanguage = createSelector(
  selectFeedbackState,
  feedbackState => feedbackState.selectedLanguage
);

export const selectFeedbackSections = createSelector(
  selectFeedbackState,
  feedbackState => feedbackState.feedback?.sections
);
