import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';

import { Store } from '@ngrx/store';

import { setSelectedLanguage } from '../../../state/feedback/feedback.actions';
import { AppState } from '../../../state';

@Component({
  selector: 'feedback-last-section',
  templateUrl: './last-section.component.html',
  styleUrls: ['./last-section.component.scss']
})
export class LastSectionComponent implements OnInit {
  sectionId: number;
  selectedLanguage: string;

  constructor(
    private viewportScroller: ViewportScroller,
    private store: Store<AppState>,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    // this section is created only for scrolling to anchors after going back from details page,
    // need to be the last rendered component in order to cover all cases
    this.sectionId = JSON.parse(localStorage.getItem('feedback-section-id'));

    this.selectedLanguage = localStorage.getItem('feedback-selected-language') ?
      localStorage.getItem('feedback-selected-language') : 'en';
  }

  ngAfterViewInit() {
    if (!isNaN(this.sectionId)) {
      this.viewportScroller.scrollToAnchor('section-' + this.sectionId);
      localStorage.clear();
    }

    setTimeout(() => {
      this.translate.use(this.selectedLanguage);
      this.store.dispatch(setSelectedLanguage({ selectedLanguage: this.selectedLanguage }));
      localStorage.removeItem('feedback-selected-language');
    });
  }
}
