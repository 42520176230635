import { Component, Input, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';

import { Store } from '@ngrx/store';

import { Section } from '../../../models/section';
import { environment } from '../../../../environments/environment';
import { selectSelectedLanguage } from '../../../state/feedback/feedback.selectors';
import { AppState } from '../../../state';

@Component({
  selector: 'feedback-description-section',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent implements OnInit {
  @Input() section: Section;

  environment = environment;
  selectedLanguage: string;

  hasSectionTitle: boolean;
  hasSectionText: boolean;
  hasContentTitle: boolean;
  hasContentText: boolean;
  hasImageCaption: boolean;

  constructor(
    private store: Store<AppState>,
    private viewportScroller: ViewportScroller
  ) {}

  ngOnInit(): void {
    this.store.select(selectSelectedLanguage).subscribe(selectedLanguage => {
      this.selectedLanguage = selectedLanguage;
    });

    this.hasSectionTitle = this.section.translations
      .find((translation) =>
        (translation.identifier === 'section-title') && translation.language === this.selectedLanguage)
      .translation !== '';

    this.hasSectionText = this.section.translations
      .find((translation) =>
        (translation.identifier === 'section-text') && translation.language === this.selectedLanguage)
      .translation !== '';

    this.hasContentTitle = this.section.translations
      .find((translation) =>
        (translation.identifier === 'content-title') && translation.language === this.selectedLanguage)
      .translation !== '';

    this.hasContentText = this.section.translations
      .find((translation) =>
        (translation.identifier === 'content-text')  && translation.language === this.selectedLanguage)
      .translation !== '';

    this.hasImageCaption = this.section.translations
      .find((translation) =>
        (translation.identifier === 'section-image-caption')  && translation.language === this.selectedLanguage)
      .translation !== '';
  }

  scrollToSection(sectionId: number): void {
    this.viewportScroller.scrollToAnchor('section-' + sectionId);
  }
}
