<div class="section-holder">
  <div class="section-title-holder" *ngIf="hasSectionTitle">
    <h1>{{ section?.translations | feedbackTranslate: 'section-title': selectedLanguage }}</h1>
  </div>
  <div class="section-description-holder" *ngIf="hasSectionText"
   [innerHTML]="section?.translations | feedbackTranslate: 'section-text': selectedLanguage"
  ></div>
  <div [ngClass]="hasSectionText ? 'section-content-holder section-has-description' : 'section-content-holder'">
    <div class="items-holder">
      <div class="item" *ngFor="let item of section.subsections">
        <div class="item-holder" *ngIf="activeItemId === item.id">
          <div class="item-info-holder">
            <div class="item-image-title-holder">
              <div class="item-image-holder">
                <img [src]='environment?.mediaUrl + item?.icon'/>
              </div>
              <div class="item-title-holder">
                <h3>{{ item?.translations | feedbackTranslate: 'subsection-name': selectedLanguage }}</h3>
              </div>
            </div>
            <div class="item-description-holder"
                 [innerHTML]="item?.translations | feedbackTranslate: 'section-graph-summary': selectedLanguage"
            ></div>
            <div class="item-button-holder" *ngIf="item.has_details">
              <a mat-raised-button
                 color="accent"
                 (click)="goToDetail(item)">
                {{ 'more_details' | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="graph-holder">
      <canvas id="canvas" baseChart
        [datasets]="profileChartData"
        [labels]="profileChartLabels"
        [legend]="profileChartLegend"
        [chartType]="profileChartType"
        [options]="profileChartOptions"
        (chartClick)="profileChartClicked($event)"
      ></canvas>
    </div>
  </div>
</div>
