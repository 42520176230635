import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Feedback } from '../../models/feedback';
import { ApiService } from '../../core/services/api.service';
import { downloadCCPDF } from '../../state/feedback/feedback.actions';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  constructor(private apiService: ApiService) {}

  getFeedbackByHash(templateHash: string): Observable<Feedback> {
    return this.apiService.get<Feedback>(`/feedback/${templateHash}`);
  }

  downloadPDF(templateHash: string, participantHash: string, selectedLanguage: string): Observable<any> {
    return this.apiService.get(`/feedback-pdf/${templateHash}/${participantHash}/${selectedLanguage}`, { responseType: 'arraybuffer'});
  }
}
