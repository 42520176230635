import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { selectFeedback, selectIsFeedbackLoaded } from '../../state/feedback/feedback.selectors';
import { AppState } from '../../state';
import { Feedback } from '../../models/feedback';
import { environment } from '../../../environments/environment';
import { selectIsResultsLoaded } from '../../state/results/results.selectors';

@Component({
  selector: 'feedback-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  feedbackLoaded$: Observable<boolean>;
  resultsLoaded$: Observable<boolean>;
  templateWithoutResults = true;

  feedback: Feedback;
  environment = environment;

  constructor(
    private store: Store<AppState>,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.feedbackLoaded$ = this.store.select(selectIsFeedbackLoaded);
    this.resultsLoaded$ = this.store.select(selectIsResultsLoaded);

    this.store.select(selectFeedback).subscribe(feedback => {
      this.feedback = feedback;
    });

    if (this.router.url.includes('user')) {
      this.templateWithoutResults = false;
    }
  }
}
