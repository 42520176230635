<div class="section-holder">
  <div class="section-title-holder" *ngIf="hasSectionTitle">
    <h1>{{ section?.translations | feedbackTranslate: 'section-title': selectedLanguage }}</h1>
  </div>
  <div class="section-description-holder" *ngIf="hasSectionText"
       [innerHTML]="section?.translations | feedbackTranslate: 'section-text': selectedLanguage"
  ></div>
  <div [ngClass]="hasSectionText ? 'section-content-holder section-has-description' : 'section-content-holder'">
    <div class="info-holder">
      <div class="content-title-holder" *ngIf="hasContentTitle">
        <h3>{{ section?.translations | feedbackTranslate: 'content-title': selectedLanguage }}</h3>
      </div>
      <div class="content-text-holder" *ngIf="hasContentText"
           [innerHTML]="section?.translations | feedbackTranslate: 'content-text': selectedLanguage">
      </div>
      <div class="content-button-holder" *ngIf="section?.hyperlink || section?.link_to_section">
        <a mat-raised-button
         color="accent"
         *ngIf="section?.hyperlink"
         [href]="section?.hyperlink" target="_blank">
          {{ section?.translations | feedbackTranslate: 'section-button-text': selectedLanguage }}
        </a>
        <a mat-raised-button
         color="accent"
         *ngIf="section?.link_to_section"
         (click)="scrollToSection(section.link_to_section)">
          {{ section?.translations | feedbackTranslate: 'section-button-text': selectedLanguage }}
        </a>
      </div>
    </div>
    <div [ngClass]="hasImageCaption ? 'image-holder image-has-caption' : 'image-holder'">
      <img [src]='environment?.mediaUrl + section?.image_url'/>
      <span class="image-caption small-description" *ngIf="hasImageCaption">
        {{ section?.translations | feedbackTranslate: 'section-image-caption': selectedLanguage }}
      </span>
    </div>
  </div>
</div>
