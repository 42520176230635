import { Action, createReducer, on } from '@ngrx/store';

import { Feedback } from '../../models/feedback';
import * as feedbackActions from '../feedback/feedback.actions';

export const feedbackFeatureKey = 'feedback';

export interface FeedbackState {
  loadingFeedback: boolean;
  feedbackLoaded: boolean;
  initialized: boolean;
  feedback: Feedback;
  selectedLanguage: string;
}

export const initialState: FeedbackState = {
  loadingFeedback: false,
  feedbackLoaded: false,
  initialized: false,
  feedback: null,
  selectedLanguage: ''
};

export const reducer = createReducer(
  initialState,
  on(feedbackActions.loadFeedback, state => {
    return {
      ...state,
      loadingFeedback: true,
      feedbackLoaded: false
    };
  }),
  on(feedbackActions.loadFeedbackSuccess, (state, { feedback }) => {
    return {
      ...state,
      loadingFeedback: false,
      feedbackLoaded: true,
      initialized: true,
      feedback,
      selectedLanguage: feedback?.languages?.split(',')[0]
    };
  }),
  on(feedbackActions.loadFeedbackFailure, state => {
    return {
      ...state,
      loadingFeedback: false,
      feedbackLoaded: false,
      initialized: true
    };
  }),
  on(feedbackActions.setSelectedLanguage, (state, { selectedLanguage }) => {
    return {
      ...state,
      selectedLanguage
    };
  }),
  on(feedbackActions.downloadCCPDF, state => {
    return {
      ...state,
      loadingFeedback: false,
      feedbackLoaded: true
    };
  }),
);

export function feedbackReducer(state: FeedbackState | undefined, action: Action): FeedbackState {
  return reducer(state, action);
}
